import React, { useEffect, useState } from "react";
import { BookmarkController } from "../../../API/BookmarkController";
import { Bookmark } from "../../../models/bookmark.interface";
import './BookmarksAccordion.css'
import Remove from "./Icons/removeicon.png"
import "./BookmarksAccordion.css"
import SelectedGreenIcon from "./Icons/SelectedGreenIcon.png"
import { Link, useLocation } from "react-router-dom";
import { auth, subscribeToBookmarkList } from "../../../API/FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import DeleteToast from "../../../utils/DeleteToast";

function BookmarksAccordion(props: any) {

    const [user] = useAuthState(auth);
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([])
    const [bookmarkSelected, setBookmarkSelected] = useState(localStorage.getItem('passage')! + localStorage.getItem('translation')!)
    const userId = user?.uid
    const study = props.study
    const editBookmarks = props.editBookmarks
    const [open, setOpen] = useState(false)
    const [time, setTime] = useState<number>()
    const [deletedBookmark, setDeletedBookmark] = useState<Bookmark>()

    const handleBookmarkDelete = (bookmark: Bookmark) => {
        if (userId !== undefined) {
            setDeletedBookmark(bookmark)
            openToast()
            console.log(bookmark)
            BookmarkController.RemoveBookmark(bookmark.id, userId, study)
        }
    }

    const closeToast = (event: React.SyntheticEvent | React.MouseEvent, reason?: string)  => {
        if (reason !== 'clickaway') {
            setOpen(false)
        }
    }
    const openToast = () => {
        setOpen(true)
    }

    const undoDelete = () => {
        BookmarkController.AddBookmarksNewStudyFromChallenge(
            deletedBookmark!.id, 
            deletedBookmark!.uid, 
            deletedBookmark?.study, 
            deletedBookmark!.reference,
            deletedBookmark!.book,
            deletedBookmark!.chapter,
            deletedBookmark!.verse,
            deletedBookmark!.translation)
        setOpen(false)
    }

    const setBookmark = (bookmark: Bookmark) => {
        setBookmarkSelected(bookmark.id)
        props.passage(bookmark.reference.replace(/ /g, ""))
        props.translation(bookmark.translation)
        localStorage.setItem('passage', bookmark.reference); 
        localStorage.setItem('book', bookmark.book);
        localStorage.setItem('chapter', bookmark.chapter);
        localStorage.setItem('verses', bookmark.verse);
        localStorage.setItem('translation', bookmark.translation)
    }

    useEffect(() => {
        setBookmarkSelected(localStorage.getItem('passage')! + localStorage.getItem('translation'))
    }, [localStorage.getItem('translation'), localStorage.getItem('passage')])

    React.useEffect(() => {
        if (userId != null){
            if(bookmarks.length < 1) {
                subscribeToBookmarkList(setBookmarks, userId, study);
                console.log("subscribing to bookmarks")
            }
        }
    })
    
    if (user) {
        if (study === "null" && bookmarks.length === 1) {
            return <AddBookmarkPrompt />
        }
        else if (bookmarks.length >= 1) {
            return(
                <div className="BookmarksAccordion-background" >
                    {bookmarks.map((bookmark: Bookmark, index) => {
                        if (bookmark.id !== 'null') {
                            if (bookmarkSelected === bookmark.id) {
                                return(<BookMarkSelected setBookmark={setBookmark} bookmark={bookmark} bookmarks={bookmarks} editBookmarks={editBookmarks} handleBookmarkDelete={handleBookmarkDelete} />)
                            } else {
                                return(<BookMarkNotSelected setBookmark={setBookmark} bookmark={bookmark} bookmarks={bookmarks} editBookmarks={editBookmarks} handleBookmarkDelete={handleBookmarkDelete} />)
                            }
                        } else {
                            return (<></>)
                        } 
                    })}
                    <DeleteToast open={open} time={time} closeToast={closeToast} undoDelete={undoDelete} message={"Deleting Bookmark"}/>
                </div>
            )
        } else {
            return <AddBookmarkPrompt />
        }
    } else {
        return <SignInPrompt />
    }
}

function AddBookmarkPrompt() {
    return(
        <div className="BookmarksAccordian-notSignedIn"> 
            <div className="BookmarksAccordian-notSignedIn-text"> 
                Click the bookmark icon to add a Bookmark
            </div>
        </div>
    )
}

function SignInPrompt() {
    return(
        <div className="BookmarksAccordian-notSignedIn"> 
            <Link className="BookmarksAccordian-notSignedIn-text" to={"/login"}> 
                Login to View Your Bookmarks
            </Link>
        </div>
    )
}

function DeleteButton(props: any) {
    return(
        <>
            {props.editBookmarks && props.bookmarks.length > 1 ? (
                <button className="BookmarksAccordion-remove-button" onClick={() => props.handleBookmarkDelete(props.bookmark)}> 
                    <img className="BookmarksAccordion-remove-icon"  src={Remove} alt ="Remove Verse" /> 
                </button>
            ):(
                <></>
            )}
        </>
    )
}

function BookMarkSelected(props: any) {
    return (
        <div key={"button-row"} className="BookmarksAccordion-button-row"> 
            <button 
                key="selected-button"
                className="BookmarksAccordion-selected-button" 
                onClick={() => props.setBookmark(props.bookmark)}
                >
                <div key="reference-row" className="BookmarksAccordion-reference-row">
                    <img key="selected-icon" className="BookmarksAccordion-selected-icon"  src={SelectedGreenIcon} alt ="Selected" /> 
                        <div key ={props.bookmark.reference} className="BookmarksAccordion-selected-reference"> 
                        {props.bookmark.book} {props.bookmark.chapter}: {props.bookmark.verse} {props.bookmark.translation.toUpperCase()}
                        </div>
                </div>
            </button>
            <DeleteButton editBookmarks={props.editBookmarks} bookmark={props.bookmark} bookmarks={props.bookmarks} handleBookmarkDelete={props.handleBookmarkDelete} />
        </div>
    )
}

function BookMarkNotSelected(props: any) {
    return (
        <div className="BookmarksAccordion-button-row"> 
        <button 
            className="BookmarksAccordion-selected-button" 
            onClick={() => props.setBookmark(props.bookmark)}
            >
            <div className="BookmarksAccordion-reference-row">
                    <div key ={props.bookmark.reference} className="BookmarksAccordion-reference"> 
                    {props.bookmark.book} {props.bookmark.chapter}: {props.bookmark.verse} {props.bookmark.translation.toUpperCase()}
                    </div>
            </div>
        </button>
        <DeleteButton editBookmarks={props.editBookmarks} bookmarks={props.bookmarks} handleBookmarkDelete={props.handleBookmarkDelete} />
    </div>
    )
}
export default BookmarksAccordion