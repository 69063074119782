
import React, { useState } from "react";
import Nav from "./Components/Nav/Nav"
import Home from "./Components/Pages/Home/Home"
import About from "./Components/Pages/About/About"
import background from '././Images/biblebackground3.jpg'
import Login from "./Components/Pages/Login/Login";
import Register from "./Components/Pages/Register/Register";
import Reset from "./Components/Pages/ResetPassword/Reset";
import Dashboard from "./Components/Pages/UserDashboard/Dashboard";
import {
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import { useAuth } from "./Provider/AuthProvider";
import ChallengesPage from "./Components/Pages/Challenges/ChallengesPage";

function App() { 

  if (localStorage.getItem('passage') === null) {
    localStorage.setItem('passage', 'John3:16-17')
  }
  if (localStorage.getItem('book') === null) {
    localStorage.setItem('book', 'John')
  }
  if (localStorage.getItem('chapter') === null) {
    localStorage.setItem('chapter', '3')
  }
  if (localStorage.getItem('verses') === null) {
    localStorage.setItem('verses', '16-17')
  }

  if (localStorage.getItem('translation') === null) {
    localStorage.setItem('translation', 'web')
  }

  window.addEventListener('keydown', function(event) {
    if (event.code === "ArrowRight" || event.code === "ArrowLeft" || event.code === "ArrowDown" || event.code === "ArrowUp") {
      document.getElementById("Home-textField")!.focus();
    }
  })

  const user = useAuth()  
  const [hamburgerView, setHamburgerView] = useState(false)

  function UpdateHamburgerMenu(hamburgerMenu: boolean) {
    setHamburgerView(hamburgerMenu)
  }

  return (
    <div style = {{backgroundImage: `url(${background})` }} >
      <div className="brightness">
        <div className="App" >
          <Nav hamburgerMenu= {UpdateHamburgerMenu}/> 
          <div className="App-background">
            <Routes>
              <Route path="/" element={<Home user={user} hamburgerMenu={hamburgerView}/>} />
              <Route path="/challenges" element={<ChallengesPage user={user}/>} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
  }


export default App;
