import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, Unsubscribe, where } from "firebase/firestore"
import { Study } from "../models/study.interface";
import { db } from "./FirebaseConfig"

export class StudiesController {

    static async subscribeToStudyList(
        onChange: (items: Study[]) => void,
        userId: string,
      ): Promise<Unsubscribe> {
        const q = query(collection(db, "studies"), where("uid", "==", userId))
          return onSnapshot(q, (col) => {
            onChange(
                col.docs.map((doc) => {
                    const data = doc.data();
                    const id = doc.id;
                    return { id, ...data };
                }) as Study[]
            );
            if(col.docs.length === 0) {
              console.log("No studies")
            }
        });
      };

    static async AddStudy(study: string | undefined,  uid: string | undefined) {
        if (study !== undefined && uid !== undefined) {
            const docRef = doc(db, 'studies', study.replace(/\s/g, "") + uid)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
            } else {
                const addRef = await setDoc(docRef, {
                    id: study.replace(/\s/g, "") + uid,
                    uid: uid,
                    name: study
                })
            }
        }
    }

    static async idExists(study: string, uid: string) {
        const docRef = doc(db, 'studies', study + uid);
        const docSnap = await getDoc(docRef); 
        if (docSnap.exists()) {
            return true
        } else {
            return false
        }
    }

    static async RemoveStudy(id: string, uid: string) {
        await deleteDoc(doc(db, 'studies', id));
    }
}