import ChallengesAccordian from "./ChallengesAccordian"
import "./Challenges.css"

function ChallengesPage(props: any) {

    const user = props.user
    
    return(
        <div className="Challenges-page-container">
            <ChallengesAccordian user={user?.uid}/>
        </div>
    )
}
export default ChallengesPage