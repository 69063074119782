import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../home.modules.css';
import Keyboard from "../Icons/keyboardicon.png"

function KeyboardHint() {

    const [startOpacity, setStartOpacity] = useState(0)
    const [endOpacity, setEndOpacity] = useState(1)
    const location = useLocation();

    const pathChange = () => {
        if (location.pathname === "/") {
            setTimeout(function() {
                setStartOpacity(1)
                setEndOpacity(0)
            }, 1500);
        }
    }

    useEffect(() => {
        pathChange()
    }, [])

    window.addEventListener('keydown', function(event) {
        setStartOpacity(1)
        setEndOpacity(0)
    })

    return(
        <motion.div 
            className="Home-arrow-keys-container"
            initial={{ opacity: startOpacity }}
            animate={{ opacity: endOpacity }}
            transition={{ duration: 0.2 }}
            >
            <img className="Home-arrow-keys-icon" src={Keyboard} alt="Keyboard Icon" />
            <p className="Home-arrow-keys-text"> Use the Space bar and Arrow Keys to move through the verse </p>
        </motion.div>
    )
}
export default KeyboardHint