import { useEffect, useState } from "react"
import BookmarksAccordion from "../../Bookmarks/BookmarksAccordion"
import ChallengesAccordian from "../../Challenges/ChallengesAccordian"
import NewStudy from "../../Studies/NewStudy"
import StudiesAccordion from "../../Studies/StudiesAccordion"
import "./ListOptions.css"
import EditIcon from "./Icons/editicon.png"
import EditBookmarksHeader from "../../Bookmarks/EditBookmarksHeader"

function ListOptions(props: any) {

    const [selectedPassage, setSelectedPassage] = useState("John3:16-17")
    const [selectedTranslation, setSelectedTranslation] = useState("web")
    const user = props.user

    function updateMyPassage(passage: string) {
        setSelectedPassage(passage)
    }

    function updateMyTranslation(translation: string) {
        setSelectedTranslation(translation)
    }

    useEffect(() => {
        setSelectedPassage(props.passage)
        setSelectedTranslation(props.translation)
    }, [selectedPassage, selectedTranslation])

    function BookmarksList() {

        const [editBookmarks, setEditBookmarks] = useState(false)
        let study = "null"

        function updateEditBookmarks(editBookmarks: boolean) {
            setEditBookmarks(editBookmarks)
        }
   
        return(
            <>
                <EditBookmarksHeader editBookmarks={updateEditBookmarks}/>
                <div className="Bookmarks-list-background">
                    <BookmarksAccordion passage={updateMyPassage} translation={updateMyTranslation} user={user?.uid} study={study} editBookmarks={editBookmarks}/>
                </div>
            </>
        )
    } 
    
    function StudiesList() {

        const [editStudies, setEditStudies] = useState(false)

        function updateEditStudies(editStudies: boolean) {
            setEditStudies(editStudies)
        }
        
        return(
            <>
                <NewStudy editStudies={updateEditStudies}/>
                <StudiesAccordion passage={updateMyPassage} translation={updateMyTranslation} user={user?.uid} editStudies={editStudies}/>
            </>
        )
    }
       
    function SuggestedStudiesList() {
        return(
            <ChallengesAccordian user={user?.uid}/>
        )
    }

    let bookmark: [JSX.Element, string] = [<BookmarksList/>, "Bookmarks"]
    let studies: [JSX.Element, string] = [<StudiesList/>, "Studies"]
    let suggestedStudies: [JSX.Element, string] = [<SuggestedStudiesList/>, "Suggested Studies"]
    const listArray = [bookmark, studies, suggestedStudies]

    const [selectedList, setSelectedList] = useState<[JSX.Element, string]>([<BookmarksList/>, "Bookmarks"])
    let selectedListName = selectedList[1]
    let selectedListElement = selectedList[0]
    
    return(
        <>
            <div className="List-options">
                {listArray.map(element => {
                    let listElement = element[0]
                    let listName = element[1]
                    if (selectedListName === listName) {
                        return(
                            <button 
                                className="List-selected-options-buttons"
                                onClick={() => {setSelectedList(element)}}
                                > 
                                {listName}
                            </button>
                        )
                    } else {
                    return(
                        <button 
                            className="List-options-buttons"
                            onClick={() => {setSelectedList(element); if (listName === "Bookmarks") localStorage.setItem("study", "null")}}
                            > 
                            {listName}
                        </button>
                    )
                }})}
            </div>
            {selectedListElement}
        </>
    )
}
export default ListOptions