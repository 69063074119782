import React, { JSXElementConstructor, useEffect, useState} from "react";
import './NewVersefromHome.css'
import Book from "./Selectors/Book";
import Chapter from './Selectors/Chapter'
import Verse from './Selectors/Verse'
import ChangeTranslation from "./ChangeTranslation/ChangeTranslation";
import { motion } from "framer-motion";
import SetBookMark from "../../Bookmarks/SetBookMark";
import SelectorsView from "./Selectors/SelectorsView";


function NewVerseFromHome(props: any) {

    const [selector, setSelector] = useState(<Book book={updateMyBook} bookName={updateMyBookName}/>)
    const [selectedBook, setSelectedBook] = useState("JHN")
    const [selectedBookName, setSelectedBookName] = useState(localStorage.getItem('book')!)
    const [selectedChapter, setSelectedChapter] = useState(localStorage.getItem('chapter')!)
    const [selectedVerses, setSelectedVerses] = useState(localStorage.getItem('verses')!)
    const [selectedPassage, setSelectedPassage] = useState(localStorage.getItem('passage')!)
    const [selectedTranslation, setSelectedTranslation] = useState(localStorage.getItem('translation')!)
    const [initMode, setInitMode] = useState<string>()
    const [height, setHeight] = useState("0vh")
    const [bookStatus, setBookStatus] = useState(false)
    const [chapterStatus, setChapterStatus] = useState(false)
    const [verseStatus, setVerseStatus] = useState(false)
    let uid = props.user

    const passage = selectedBookName + " " + selectedChapter + ": " + selectedVerses

    useEffect(() => {
        setSelectedBookName(localStorage.getItem('book')!)
        setSelectedChapter(localStorage.getItem('chapter')!)
        setSelectedVerses(localStorage.getItem('verses')!)
        setSelectedPassage(localStorage.getItem('passage')!)
        setSelectedTranslation(localStorage.getItem('translation')!)
        if(localStorage.getItem('translation') === "web") {
            setInitMode("0%")
        } else if (localStorage.getItem('translation') === "kjv") {
            setInitMode("100%")
        }
    }, [localStorage.getItem('passage'), localStorage.getItem('translation')])

    function updateMyBook(book: string) {
        setSelectedBook(book)
        setBookStatus(false)
        setChapterStatus(true)
    }

    function updateMyBookName(bookName: string) {
        setSelectedBookName(bookName)
    }

    function updateMyChapter(chapter: string) {
        setSelectedChapter(chapter)
        setChapterStatus(false)
        setVerseStatus(true)
    }

    function updateMyVerses(verses: string) {
        setSelectedVerses(verses)
        setVerseStatus(false)
    }

    function updateMyTranslation(translation: string) {
        setSelectedTranslation(translation)
    }

    function Reference() {
            const pBook = selectedBookName
            const pChapter = selectedChapter
            const pVerses = selectedVerses
            return (
            <>
                <div className = "NewVersefromHome-reference"> 
                    <button className= "NewVersefromHome-reference-buttons" onClick={(() => {
                        if (bookStatus) {
                        setHeight("0vh")
                        setBookStatus(false)
                        setSelectedChapter(localStorage.getItem('chapter')!)
                        setSelectedVerses(localStorage.getItem('verses')!)
                        } else {
                        setHeight("20vh")
                        setBookStatus(true)
                        setChapterStatus(false)
                        setVerseStatus(false)
                        setSelector(
                            <Book 
                                book={updateMyBook} 
                                bookName={updateMyBookName}
                            />
                    )}})}> {pBook} </button>
                    <button className= "NewVersefromHome-reference-buttons" onClick={(() => {
                        if (chapterStatus === true && selectedChapter !== "") {
                            setHeight("0vh")
                            setChapterStatus(false)
                            setSelectedVerses(localStorage.getItem('verses')!)
                        } else if (chapterStatus === false) {
                        setHeight("20vh")
                        setBookStatus(false)
                        setChapterStatus(true)
                        setVerseStatus(false)
                        setSelector(
                            <Chapter 
                                book={selectedBook} 
                                chapter={updateMyChapter}
                            />
                    )}})}> {pChapter}: </button>
                    <button className= "NewVersefromHome-reference-buttons" onClick={(() => {
                        if (verseStatus === true && selectedVerses !== "") {
                            setHeight("0vh")
                            setVerseStatus(false)
                        } else if (verseStatus === false) {
                        setHeight("20vh")
                        setBookStatus(false)
                        setChapterStatus(false)
                        setVerseStatus(true)
                        setSelector(
                            <Verse 
                                book={selectedBook} 
                                chapter={selectedChapter} 
                                verses={updateMyVerses}
                            />
                    )}})}> {pVerses} </button>
                </div>
            </>
            )
    }

    useEffect(() => {
        if(bookStatus) {
            setSelector(
                <Book 
                    book={updateMyBook} 
                    bookName={updateMyBookName}
                />
            )
            setSelectedChapter("")
            setSelectedVerses("")
        }
        if(chapterStatus) {
            setSelector(
                <Chapter 
                    book={selectedBook} 
                    chapter={updateMyChapter}
                />
            )
            setSelectedVerses("")
        }
        if(verseStatus) {
            setSelector(
                <Verse 
                    book={selectedBook} 
                    chapter={selectedChapter} 
                    verses={updateMyVerses}
                />
            )
        } else if (bookStatus === false && chapterStatus === false  && verseStatus === false) {
            if (selectedBook !== "" && selectedChapter !== "" && selectedVerses !== "") {
                setSelectedPassage(passage);
            }
            setHeight("0vh")
        }
    }, [verseStatus, bookStatus, chapterStatus, passage, selectedBook, selectedChapter, selectedVerses])

    useEffect(() => {
        props.passage(selectedPassage.replace(/ /g, ""))
        props.translation(selectedTranslation)
        localStorage.setItem('passage', selectedPassage.replace(/ /g, ""))
        localStorage.setItem('book', selectedBookName)
        localStorage.setItem('chapter', selectedChapter)
        localStorage.setItem('verses', selectedVerses)
        setSelectedPassage(localStorage.getItem('passage')!)
        if(localStorage.getItem('translation') === "web") {
            setInitMode("0%")
        } else if (localStorage.getItem('translation') === "kjv") {
            setInitMode("100%")
        }
    }, [selectedPassage, selectedTranslation])

    return(
        <div className="NewVersefromHome"> 
            <div className="NewVerse-reference-row"> 
                <Reference />
                <div className="NewVerse-bookmark-and-switch"> 
                    <SetBookMark user={uid}/>
                    <ChangeTranslation translation={updateMyTranslation} initMode={initMode}/>
                </div>
            </div>
            {SelectorsView(height, selector)}
        </div>
    )
}
export default NewVerseFromHome