import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { styled } from '@mui/material/styles';
import '../utils/DeleteToast.css'

const CustomSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
    '&.MuiSnackbarContent-root': {
    backgroundColor: '#e9e0d1',
    color: 'black'
   }
}));

function DeleteToast(props: any) {

    return (
        <div>
            <Snackbar
                open={props.open}
                autoHideDuration={6000}
                onClose={props.closeToast}
            >
                <CustomSnackbarContent
                    action={
                        <button className='delete-toast-undo-button'
                            onClick={props.undoDelete}>
                            Undo
                        </button>
                    }
                    message={props.message}
                />
            </Snackbar>
        </div>
    )
}

export default DeleteToast