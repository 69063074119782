import { useEffect, useState } from "react"
import Remove from "./Icons/removeicon.png"
import StudiesTextField from "./StudiesTextField"
import "./NewStudy.css"
import { StudiesController } from "../../../API/StudiesController"
import { useAuth } from "../../../Provider/AuthProvider"
import { BookmarkController } from "../../../API/BookmarkController"
import EditIcon from "./Icons/editicon.png"

function NewStudy(props: any) {

    const user = useAuth() 
    const uid = user?.uid 
    const [newStudyView, setNewStudyView] = useState(false)
    const [val, setVal] = useState("")
    const [newStudy, setNewStudy] = useState<string | undefined>()
    const [editStudies, setEditStudies] = useState(false)
    const passageId = localStorage.getItem('passage')! + localStorage.getItem('translation')!

    useEffect(() => {
        if (uid !== null && newStudy !== null) {
        StudiesController.AddStudy(newStudy, uid);
        BookmarkController.AddBookmarkNewStudy(passageId, uid, newStudy)
        }
    }, [newStudy])

    useEffect(() => {
        props.editStudies(editStudies)
    }, [editStudies])

    const assignNewStudy = () => {
        if (newStudyView) {
            return(
                <div className="NewStudy-input-row">
                    <StudiesTextField 
                    spellCheck = {true}
                    value = {val}
                    onChange = {setVal}
                    placeholder={""}
                    autoFocus = {true}
                    textarea = {true} 
                    />
                    <button className="NewStudy-Add-Button" onClick={() => {setNewStudy(val); setVal(""); setNewStudyView(false); setEditStudies(!editStudies)}}>
                        +
                    </button>
                </div>
            )
        }
    }
    
    return(
        <div className="NewStudy">
            <div className="NewStudy-button-row"> 
                <div className="NewStudy-reference-row">
                    <div className="NewStudy-reference">
                        Studies
                    </div>
                    <button className="NewStudy-edit-button" onClick={() => {setNewStudyView(!newStudyView); setEditStudies(!editStudies)}}>
                        <img className="NewStudy-edit-icon" src={EditIcon} alt="Edit Studies Icon" />
                    </button>
                </div>
            </div>
            {assignNewStudy()}
        </div>
    )
}
export default NewStudy