import { useEffect, useState } from "react"
import EditIcon from './Icons/editicon.png'

function EditBookmarksHeader(props: any) {

    const [editBookmarks, setEditBookmarks] = useState(false)
    
    useEffect(() => {
        props.editBookmarks(editBookmarks)
    }, [editBookmarks])

    return(
        <div className="Bookmarks">
            <div className="Bookmarks-button-row"> 
                <div className="Bookmarks-reference-row">
                    <div className="Bookmarks-reference">
                        Bookmarks
                    </div>
                    <button className="Bookmarks-edit-button" onClick={() => {setEditBookmarks(!editBookmarks)}}>
                        <img className="Bookmarks-edit-icon" src={EditIcon} alt="Edit Bookmarks Icon" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default EditBookmarksHeader