import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, subscribeToBookmarkList } from "../../../API/FirebaseConfig";
import { StudiesController } from "../../../API/StudiesController";
import { Study } from "../../../models/study.interface";
import "./StudiesAccordion.css"
import Remove from "./Icons/removeicon.png"
import BookmarksAccordion from "../Bookmarks/BookmarksAccordion";
import EditIcon from "./Icons/editicon.png"
import { Bookmark } from "../../../models/bookmark.interface";
import React from "react";
import { bookmarkCollection, BookmarkController } from "../../../API/BookmarkController";
import { Link } from "react-router-dom";
import DeleteToast from "../../../utils/DeleteToast";


function StudiesAccordion(props: any) {

    const [studies, setStudies] = useState<Study[]>([])
    const uid = props.user
    const [currentStudy, setCurrentStudy] = useState(localStorage.getItem("study"))
    const [selectedPassage, setSelectedPassage] = useState("John3:16-17")
    const [selectedTranslation, setSelectedTranslation] = useState("web")
    const editStudies = props.editStudies
    const [editBookmarks, setEditBookmarks] = useState(false)
    const [deleteBookmark, setDeleteBookmark] = useState(false)
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([])
    const [open, setOpen] = useState(false)
    const [deletedStudy, setDeletedStudy] = useState<Study>()
    const [deletedBookmarks, setDeletedBookmarks] = useState<Bookmark[]>()


    const handleRemoveStudy = (study: Study) => {
        if(bookmarks.length < 1) {
            subscribeToBookmarkList(setBookmarks, study.uid, study.name)
        };
        StudiesController.RemoveStudy(study.id, study.uid)
        setDeletedStudy(study)
        setDeletedBookmarks(bookmarks)
        toastOpen()
    }

    const toastOpen = () => {
        setOpen(true)
    }

    const closeToast = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason !== 'clickaway') {
            setOpen(false)
        }
        setDeleteBookmark(!deleteBookmark)
    }

    const undoDelete = () => {
        StudiesController.AddStudy(deletedStudy?.name, deletedStudy?.uid)
        bookmarks.forEach((bookmark: Bookmark) => {
            BookmarkController.AddBookmarksNewStudyFromChallenge(
                bookmark.id, bookmark.uid, bookmark.study,
                bookmark.reference, bookmark.book, bookmark.chapter,
                bookmark.verse, bookmark.translation)
        })
        setOpen(false)
    }

    function updateMyPassage(passage: string) {
        setSelectedPassage(passage)
        props.passage(selectedPassage)
    }
    
    function updateMyTranslation(translation: string) {
        setSelectedTranslation(translation)
        props.translation(selectedTranslation)
    }

    useEffect(() => {
        setEditBookmarks(false)
    }, [currentStudy])

    useEffect(() => {
        if (uid !== undefined) {
            if(studies.length < 1) {
                StudiesController.subscribeToStudyList(setStudies, uid);
                console.log("subscribing to bookmarks")
            }
        } else {
            
        }
    })

    useEffect(() => {
        
        for (let i = 0; i < bookmarks.length; i++) {
            BookmarkController.RemoveBookmark(bookmarks[i].id, bookmarks[i].uid, bookmarks[i].study)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteBookmark])


    if (uid !== undefined) {
        if (studies.length > 1) {
            return(
                <div className="Studies-background">
                    {studies.map((study: Study, index) => {
                        const deleteButton = () => {
                            if (editStudies) { 
                                return(
                                    <button key="remove-button" className="BookmarksAccordion-remove-button" onClick={() => handleRemoveStudy(study)} > 
                                        <img key="remove-icon" className="BookmarksAccordion-remove-icon"  src={Remove} alt ="Remove Verse" /> 
                                    </button>
                                )
                            } else {
                                if (currentStudy === study.name) {
                                    return(
                                        <button className="Studies-edit-button" onClick={() => {setEditBookmarks(!editBookmarks)}}>
                                            <img className="Studies-edit-icon" src={EditIcon} alt="Edit Studies Icon" />
                                        </button>
                                    )
                                }
                            }
                        }
                        if (study.id !== "null") {
                            if (currentStudy === study.name && editStudies !== true) {
                                return(
                                    <div className="Studies-container">
                                        <div key={study.id} className="Studies-selected-button-row">
                                            <button key={study.id} 
                                                className="Studies-button" 
                                                onClick={() => {
                                                    setCurrentStudy(study.name);
                                                    localStorage.setItem("study", study.name)
                                                    }}> 
                                                <div key={study.id} className="Studies-name-row">
                                                    <div key={study.name} className="Studies-selected-name"> 
                                                        {study.name}
                                                    </div>
                                                </div>
                                            </button>
                                            {deleteButton()}
                                        </div>
                                        <BookmarksAccordion passage={updateMyPassage} translation={updateMyTranslation} user={uid} study={currentStudy} editBookmarks={editBookmarks}/>
                                    </div>
                                )
                            } else {
                                return(
                                    <div className="Studies-container">
                                        <div key={study.id} className="Studies-button-row">
                                            <button key={study.id} 
                                                className="Studies-button" 
                                                onClick={() => {
                                                    setCurrentStudy(study.name);
                                                    localStorage.setItem("study", study.name)
                                                    }}> 
                                                <div key={study.id} className="Studies-name-row">
                                                    <div key={study.name} className="Studies-name"> 
                                                        {study.name}
                                                    </div>
                                                </div>
                                            </button>
                                            {deleteButton()}
                                        </div>
                                    </div>
                                )
                            }
                        } else {
                            return(
                                <></>
                            )
                        }
                    })}
                    <DeleteToast open={open} closeToast={closeToast} undoDelete={undoDelete} message={"Deleting Study"}/>
                </div>
            )
        } else {
            return(
                <div className="Studies-background"> 
                    <div className="Studies-notSignedIn-text"> 
                        Create a new Study to save your bookmark
                    </div>
                </div>
            )
        }
    } else {
        return(
            <div className="Studies-background"> 
                <Link className="Studies-notSignedIn-text" to={"/login"}> 
                    Login to View Your Studies
                </Link>
            </div>
        )
    }
}
export default StudiesAccordion