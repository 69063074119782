import React, { useEffect, useState } from "react";
import './CheckVerseTypeTwo.css'
import TextField from "./TextField/TextField"
import refresh from "./Icons/Refresh.png"
import hint from "./Icons/hinticonon.png"
import HintFull from "./Icons/hinticon.png"

interface Passage {
    reference: string;
    text: string;
  }

function CheckVerseTypeTwo(props: any) {

  const [count, setCount] = useState(0)

  let selectedPassage = localStorage.getItem("passage")
  let selectedTranslation = localStorage.getItem('translation')

  const [passage, setPassage] = useState<Passage>();
  const fetchPassage = async () => {
      const response = await fetch (
      `https://bible-api.com/${selectedPassage}?translation=${selectedTranslation}`
  );
  const data = await response.json(); 
    setPassage(data)
  };

  useEffect(() => {
    fetchPassage()
    setCount(1)
  }, [props.passage, props.translation]);

  let text = passage?.text
  let fullText = ""
  if (text != null) {
    fullText = text.trim().replace(/(\r\n|\n|\r)/gm, " "); 
  }

  const [val, setVal] = useState("")
  const [ratio, setRatio] = useState("")
  const [status, setStatus] = useState("")
  const [statusView, setStatusView] = useState(false)
  const [hintStatus, setHintStatus] = useState(false)

  let arrayWPunctuation: string [] = fullText.split(/[' '\n]+/)
  let fullTextArray: string[] = fullText.toLowerCase().split(/[' '\n]+/)

  for(let i = 0; i < fullTextArray.length; i++) {
    if (fullTextArray[i].includes("’")) {
      fullTextArray.splice(i, 1, fullTextArray[i].replace("’", "'"))
    }
    if (fullTextArray[i].includes(',') || fullTextArray[i].includes('.') || fullTextArray[i].includes(';') || fullTextArray[i].includes(':')) {
      fullTextArray.splice(i, 1, fullTextArray[i].replace(/[.,;:]/g, ""))
    }
  }

  const TextFieldCheck = fullTextArray.map((text, index) => {
    if (index < count) {
      return(
        <div className="Type2-textField ">
        {arrayWPunctuation[index]}
      </div>
      )
    }
    if (index === count) {
      return(
        <div className="Type2-textField-input">
          <TextField 
            spellCheck = {true}
            value = {val.toLowerCase().trim()}
            onChange = {setVal}
            placeholder={""}
            autoFocus = {true}
            textarea = {true} 
            />
        </div>
      )
    }
  })

  useEffect(() => {
    if (val === fullTextArray[count]) {
      setCount(count + 1)
      setVal("")
      setHintStatus(false)
    }
  }, [val])

  useEffect(() => {
    if (count === 1) {
      setStatusView(false)
    } else {
      setStatusView(true)
    }
    setRatio(Math.floor((count)/fullTextArray.length * 100).toString() + "%")
  }, [count])

  useEffect(() => {
    if (ratio === "100%") {
      setStatus("Congrats! You've completed ")
    } else {
      setStatus("Keep Going! You've completed ")
    }
  }, [ratio])

  function Status() {
    if (statusView) {
      return(
        <> 
          {status}{ratio}
        </>
      )
    } 
  }

  function Hint() {
    if (count < fullTextArray.length) {
      let wordArray: string[] = fullTextArray[count].split('')
      if (hintStatus) {
        return(
          <>
            First Letter:  {wordArray[0]}
          </>
        )
      }
    } 
  }

  function HintIcon() {
    if (hintStatus) {
      return(
      <button className="Type2-hint-button" onClick={() => {setHintStatus(!hintStatus)}}> 
        <img src={hint} alt="refresh button" className="Type2-hint-button-icon" />
      </button>
      )
    } else {
      return(
      <button className="Type2-hint-button" onClick={() => {setHintStatus(!hintStatus)}}> 
        <img src={HintFull} alt="refresh button" className="Type2-hint-button-icon" />
      </button>
      )
    }
  }

  function Refresh() {
    if (ratio === "100%" && statusView === true) {
      return(
        <button className="Type2-refresh-button" onClick={() => {setCount(0); setVal(""); setStatusView(false)}}> 
        <img src={refresh} alt="refresh button" className="Type2-refresh-button-icon" />
        </button>
      )
    }
  }

  return (
        <>
          <div className="Type2-textField-shape">
            <div className="Type2-textField-display">
              {TextFieldCheck}
            </div>
          </div>
          <div className="Type2-Status-Change">
            <div className="Type2-Status-Change-Text">
            {Status()}
            {Refresh()}
            </div>
            <div className="Type2-hint-row">  
              {Hint()}
              <>
              {HintIcon()}
              </>
            </div>
          </div>
        </>
  )
}
export default CheckVerseTypeTwo