import React from 'react'; 
import './StudiesTextField.css'

type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

interface TextFieldProps {
    spellCheck: boolean;
    value: string;
    onChange: (val: string) => void;
    placeholder?: string;
    autoFocus?: boolean;
    textarea?: boolean; 
}

const StudiesTextField = ({onChange, textarea = false, ...rest}: TextFieldProps) => {
    const InputElement = textarea ? 'textarea' : 'input'; 
    return(
        <InputElement
        className={'Studies-text-area'}
        onChange={({ target : { value } }: InputChangeEvent) => onChange(value)}
        {...rest}
        />
    );
};

export default StudiesTextField; 