import React, { useState } from "react";
import './About.modules.css'

function About() {

    return(
        <div className="About"> 
            <p className="Banner">"One word at a time"</p>
            <div className="Left-side">
                <p className="Paragraphs">This app was designed to implement the techniques Kathleen Dalton uses to memorize whole books of the Bible - one word at a time. </p>
                <p className="Paragraphs">Kathleen Dalton fell in love with the Lord at 17 years-old and devoted her life to Christ. 
                Years later, she was inspired to memorize whole books of the Bible, and she succeeded! 
                By preserving the Word in her heart, she is more equiped to walk in obedience to the Lord and share the gospel to every person she meets. </p>
            <p className="Paragraphs">Happy Memorizing!</p>
            <p className="Paragraphs">For more info click <a href="https://kathleendaltondotcom1.files.wordpress.com/2012/07/how-to-memorize-the-bible.pdf"> here</a> . </p>
            </div>
        </div>
    )
}
export default About