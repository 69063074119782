import React, { useEffect, useRef, useState } from "react";
import PracticeText from "./PracticeTest/PracticeText";
import NewVerseFromHome from "./NewVersefromHome/NewVerseFromHome";
import './home.modules.css';
import CheckVerseTypeTwo from "../CheckVerseTypeTwo/CheckVerseTypeTwo";
import { Toggle } from "./Switch";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import HamburgerNav from "../../Nav/HamburgerMenu";
import ListOptions from "./ListOptions/ListOptions";

function Home(props: any) {

  const [selectedPassage, setSelectedPassage] = useState(localStorage.getItem('passage'))
  const [selectedTranslation, setSelectedTranslation] = useState(localStorage.getItem('translation'))
  const [selectedMode, setSelectedMode] = useState("0%")
  const { height, width } = useWindowDimensions();
  const hamburgerView  = props.hamburgerMenu
  const user = props.user

  function updateMyPassage(passage: string) {
    setSelectedPassage(passage)
  }

  function updateMyTranslation(translation: string) {
    setSelectedTranslation(translation)
  }

  function updateMyMode(mode: string) {
    setSelectedMode(mode)
  }

  useEffect(() => {
    setSelectedPassage(props.passage)
    setSelectedTranslation(props.translation)
  }, [selectedPassage, selectedTranslation])

  function Mode() {
    if (selectedMode === "0%") {
      return(
        <PracticeText passage={selectedPassage} translation={selectedTranslation} />
      )
    } else {
      return(
        <CheckVerseTypeTwo passage={selectedPassage} translation={selectedTranslation}/>
      )
    }
  }

  function DisplayOptions() {
    if (width > 1150) {
      return(
        <div className="Home-studies-list"> 
          <ListOptions passage={updateMyPassage} translation={updateMyTranslation} user={user} />
        </div>
      )
    } else {

    }
  }

  function HamburgerMenu() {
    if (hamburgerView && width < 1150) {
        return(
            <HamburgerNav passage={updateMyPassage} translation={updateMyTranslation} user={user?.uid} />
        )
    }
  }

  return(
    <>
    {HamburgerMenu()}
    <div className="Home">
      <div className="Home-currentVerse">
        <div className="Home-referenceBackground "> 
          <NewVerseFromHome passage={updateMyPassage} translation={updateMyTranslation} user={user?.uid}/>
        </div>
        <div className="Home-textFieldBackground"> 
          <div className="Home-mode-row">
            <a className="Home-switch-text"> Switch Mode: </a> 
            <Toggle mode={updateMyMode} toggleOption1="Learn" toggleOption2="Test" initMode="0%"/>
          </div>
          {Mode()}
        </div>
      </div>
      {DisplayOptions()}
    </div>
    </>
  )
}

export default Home