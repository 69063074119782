import { User } from "firebase/auth"
import React from "react";
import { useEffect, useState } from "react"
import { auth } from "../API/FirebaseConfig"
import { AuthContext } from "../Context/AuthContext"

interface AuthProps {
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((User) => {
        setUser(User);
      });
  
      return unsubscribe;
    }, []);
  
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
  };

  const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(
            "useAuth must be used within an AuthProvider"
        );
    }
    return context;
  }

  export { AuthProvider, useAuth }