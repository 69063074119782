import React, { useEffect, useState} from "react";
import { Toggle } from "../../Switch";
import './ChangeTranslation.css'

function ChangeTranslation(props: any) {

    const [selectedTranslation, setSelectedTranslation] = useState(localStorage.getItem('translation')!)
    const [selectedMode, setSelectedMode] = useState<string>()

    function updateMyMode(mode: string) {
        setSelectedMode(mode)
    }

    useEffect(() => {
        if (selectedMode === "0%") {
            setSelectedTranslation("web")
        } else {
            setSelectedTranslation("kjv")
        }
    }, [selectedMode])

    useEffect(() => {
        localStorage.setItem('translation', selectedTranslation)
        props.translation(selectedTranslation)
    }, [selectedTranslation])

    return(
        <Toggle mode={updateMyMode} toggleOption1="WEB" toggleOption2="KJV" initMode={props.initMode}/>
    )
}
export default ChangeTranslation