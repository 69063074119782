import { useEffect, useState } from "react"
import { BookmarkController } from "../../../API/BookmarkController"
import BookmarkSelected from "./Icons/BookMarkSelected.png"
import Bookmark from "./Icons/BookMark.png"

function SetBookMark(props: any) {

    const [bookMarked, setBookMarked] = useState<boolean>()
    let id = (localStorage.getItem('passage')! + (localStorage.getItem('translation'))!.replace(" ", ""))
    let study = localStorage.getItem('study')!
    let uid = props.user

    useEffect(() => {
        if (uid !== undefined) {
        BookMarkExists()
        }
    }, [uid, id])

    async function BookMarkExists () {
        setBookMarked(await BookmarkController.idExists(id, uid, study))
    }

    if (uid !== undefined) {
        if(bookMarked) {
            return(
            <button className="NewVerse-SaveVerse-button" onClick={() => {setBookMarked(!bookMarked); BookmarkController.RemoveBookmark(id, uid, study)}}> 
                <img className="NewVerse-bookmark-icon" src={BookmarkSelected} alt="Save Icon" />
            </button>
            )
        } else {
            return(
            <button className="NewVerse-SaveVerse-button" onClick={() => {setBookMarked(!bookMarked); BookmarkController.AddBookmark(id, uid, study)}}> 
                <img className="NewVerse-bookmark-icon" src={Bookmark} alt="Save Icon" />
            </button>
            )
        }
    } else {
        return(
            <button className="NewVerse-SaveVerse-button" onClick={() => {}}> 
                <img className="NewVerse-bookmark-icon" src={Bookmark} alt="Save Icon" />
            </button>
        )
    }
}
export default SetBookMark