import {collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"; 
import { db } from "./FirebaseConfig";

export const bookmarkCollection = collection(db, "bookmarks")
export class BookmarkController {
    static async getBookmarks(userId: string): Promise<any> {
        return await getDocs(query(collection(db, "bookmarks"), where("uid", "==", userId))); 
    }

    static async idExists(id: string, uid: string, study: string) {
        const docRef = doc(db, 'bookmarks', id + uid + study.replace(/\s/g, ""));
        const docSnap = await getDoc(docRef); 
        if (docSnap.exists()) {
            return true
        } else {
            return false
        }
    }

    static async AddBookmark(id: string,  uid: string | undefined, study: string) {
        if (uid !== undefined) {
            const docRef = doc(db, 'bookmarks', id + uid + study.replace(/\s/g, ""))
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
            } else {
                const addRef = await setDoc(docRef, {
                    reference: localStorage.getItem('passage')!,
                    book: localStorage.getItem('book')!,
                    chapter: localStorage.getItem('chapter')!,
                    verse: localStorage.getItem('verses')!,
                    translation: localStorage.getItem('translation')!, 
                    text: null,
                    uid: localStorage.getItem('user')!,
                    id: id, 
                    study: localStorage.getItem('study')!
                })
            }
        }
    }

    static async AddBookmarkNewStudy(id: string,  uid: string | undefined, study: string | undefined) {
        if (uid !== undefined && study !== undefined) {
            const docRef = doc(db, 'bookmarks', id + uid + study!.replace(/\s/g, ""))
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
            } else {
                const addRef = await setDoc(docRef, {
                    reference: localStorage.getItem('passage')!,
                    book: localStorage.getItem('book')!,
                    chapter: localStorage.getItem('chapter')!,
                    verse: localStorage.getItem('verses')!,
                    translation: localStorage.getItem('translation')!, 
                    text: null,
                    uid: localStorage.getItem('user')!,
                    id: id, 
                    study: study!
                })
            }
        }
    }

    static async AddBookmarksNewStudyFromChallenge(id: string,  uid: string, study: string | undefined, reference: string, book: string, chapter: string, verse: string, translation: string) {
        if (uid !== undefined && study !== undefined) {
            const docRef = doc(db, 'bookmarks', id + uid + study!.replace(/\s/g, ""))
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
            } else {
                const addRef = await setDoc(docRef, {
                    reference: reference,
                    book: book,
                    chapter: chapter,
                    verse: verse,
                    translation: translation, 
                    text: null,
                    uid: uid,
                    id: id, 
                    study: study!
                })
            }
        }
    }

    static async RemoveBookmark(id: string, uid: string, study: string) {
        await deleteDoc(doc(db, 'bookmarks', id + uid + study.replace(/\s/g, "")));
    }
       
}

