import React, { useEffect, useState } from "react";
import styles from './Nav.module.css';
import { Link } from "react-router-dom";
import LoginStatus from "./LoginStatus";
import { motion } from "framer-motion";
import StudiesAccordion from "../Pages/Studies/StudiesAccordion";
import NewStudy from "../Pages/Studies/NewStudy";
import EditBookmarksHeader from "../Pages/Bookmarks/EditBookmarksHeader";
import BookmarksAccordion from "../Pages/Bookmarks/BookmarksAccordion";

function HamburgerNav(props: any) { 
    const [selectedPassage, setSelectedPassage] = useState("John3:16-17")
    const [selectedTranslation, setSelectedTranslation] = useState("web")
    const uid = props.user
    const [editStudies, setEditStudies] = useState(false)
    const [display, setDisplay] = useState("")
    const [editBookmarks, setEditBookmarks] = useState(false)

      function updateMyPassage(passage: string) {
        setSelectedPassage(passage)
      }
      
      function updateMyTranslation(translation: string) {
        setSelectedTranslation(translation)
      }

      function updateEditStudies(editStudies: boolean) {
        setEditStudies(editStudies)
      }

      function updateEditBookmarks(editBookmarks: boolean) {
        setEditBookmarks(editBookmarks)
      }

      useEffect(() => {
        props.passage(selectedPassage)
        props.translation(selectedTranslation)
        setSelectedTranslation(props.translation)
      }, [selectedPassage, selectedTranslation])

      function DisplayStories() {
        if (display === "studies") {
          return(
              <motion.div className={styles['bookmarks-list']}>
                  <NewStudy editStudies={updateEditStudies} /> 
                  <StudiesAccordion passage={updateMyPassage} translation={updateMyTranslation} user={uid} editStudies={editStudies}/>
              </motion.div>
          )
        } else {
          return(
            <button className={styles['link']} onClick={() => {setDisplay("studies")}}> 
              <div className={styles['link-text']}>Studies</div>
            </button>
          )
        }
      }

      function DisplayBookmarks() {
        if (display === "bookmarks") {
          return(
              <motion.div className={styles['bookmarks-list']}>
                <EditBookmarksHeader editBookmarks={updateEditBookmarks} />
                <div className="Bookmarks-list-background">
                  <BookmarksAccordion passage={updateMyPassage} translation={updateMyTranslation} user={uid} study={"null"} editBookmarks={editBookmarks}/>
                </div>
              </motion.div>
          )
        } else {
          return(
            <button className={styles['link']} onClick={() => {setDisplay("bookmarks"); localStorage.setItem('study', 'null')}}> 
              <div className={styles['link-text']}>Bookmarks</div>
            </button>
          )
        }
      }

    return(
        <motion.div className = {styles['Hamburger']}>  
            <div className= {styles['hamburger-links-container']}> 
              {DisplayBookmarks()}
              {DisplayStories()}
              <Link to="/" className={styles['link']}>
                <div className={styles['link-text']}>Home</div>
              </Link>
              <Link to="/challenges" className={styles['link']}>
                <div className={styles['link-text']}>Suggested Studies</div>
              </Link>
              <Link to="/about" className={styles['link']}>
                <div className={styles['link-text']}>About</div>
              </Link>
              <LoginStatus />
            </div>
        </motion.div>
    )
}
export default HamburgerNav