import "./Challenges.css"
import { useEffect, useState } from "react"
import challenges from "./challenges.json"
import { StudiesController } from "../../../API/StudiesController"
import { BookmarkController } from "../../../API/BookmarkController"

interface Challenge {
    challengeName: string
    challengeDescription: string
    passages: Array<Passage>
}

interface Passage {
        id: string 
        reference: string
        translation: string
        book: string
        chapter: string
        verse: string
}

function ChallengesAccordian(props: any) {
    const uid = props.user
    const [selectedChallenge, setSelectedChallenge] = useState("")
    const [newPassages, setNewPassages] = useState<Passage[]>([])
    const [newStudy, setNewStudy] = useState<string | undefined>()

    useEffect(() => {
        if (uid !== null && newStudy !== null) {
            StudiesController.AddStudy(newStudy, uid);
            for (let i = 0; i < newPassages.length; i++) {
                let id = newPassages[i].id
                let reference = newPassages[i].reference
                let translation = newPassages[i].translation
                let book = newPassages[i].book
                let chapter = newPassages[i].chapter
                let verse = newPassages[i].verse
                BookmarkController.AddBookmarksNewStudyFromChallenge(id, uid, newStudy, reference, book, chapter, verse, translation)
            }
        }
    }, [newStudy])

    const challengesList = () => {

        return(
            <div className="Challenges-list-background">
                {challenges.map((challenge: Challenge) => {

                    const  passages = () => {
                        if (challenge.challengeName === selectedChallenge) {
                            return(
                            <>
                                <div className="Challenges-description-container">
                                    {challenge.challengeDescription} 
                                </div> 
                                <div className="Challenges-passages-container">
                                    {challenge.passages.map((passage: Passage) => {
                                            return(
                                                <button className="Challenges-passage-button-container"> 
                                                    <div key={passage.reference} className="Challenges-passage-name-container"> 
                                                        {passage.book} {passage.chapter}: {passage.verse}
                                                    </div>
                                                </button>
                                            )
                                    })}
                                </div>
                            </>
                            )
                        }
                    }

                    const addButton = () => {
                        if (uid !== undefined) {
                            if (challenge.challengeName === selectedChallenge) {
                                return(
                                    <button className="NewStudy-Add-Button" onClick={() => {setNewPassages(challenge.passages); setNewStudy(challenge.challengeName)}}>
                                        +
                                    </button>
                                )
                            }
                        }
                    }

                    return(
                    <div className="Challenges-list-container">
                        <div className="Challenges-name-row">
                            <button 
                                key={challenge.challengeName} 
                                className="Challenges-name-container"
                                onClick={() => {
                                    if (selectedChallenge !== challenge.challengeName) {setSelectedChallenge(challenge.challengeName)}
                                    else {setSelectedChallenge("")}}}
                                >
                                {challenge.challengeName}
                            </button>
                            {addButton()}
                        </div>
                        {passages()}
                    </div>
                    )
                })}
            </div>
        )
    }

    return(
        <div className="Challenges">
            <div className="Challenges-button-row"> 
                <div className="Challenges-reference-row">
                    <div className="Challenges-reference">
                        Suggested Studies
                    </div>
                </div>
            </div>
            {challengesList()}
        </div>
    )
}
export default ChallengesAccordian