import { motion } from "framer-motion"
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from "react"

function SelectorsView(height: string, selector: JSX.Element) {
    if (height === "20vh") {
        return(
            <motion.div 
                className="NewVerse-selector-view"
                initial={{ height: "0vh" }}
                animate={{ height: height }}
                transition={{
                    type: "spring",
                    stiffness: 700,
                    damping: 100
                }}
                >  
                <div className="ListfromHome">
                    {selector}
                </div>
            </motion.div>
        )
    } else {
        return(
            <motion.div 
                initial={{ height: height }}
                animate={{ height: "0vh" }}
                transition={{
                    type: "spring",
                    stiffness: 700,
                    damping: 100
                }}
                >
            </motion.div>
        )
    }
}
export default SelectorsView