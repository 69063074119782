import React, { useEffect, useState } from "react";
import styles from './Nav.module.css';
import { Link, useLocation } from "react-router-dom";
import LoginStatus from "./LoginStatus";
import OneMoreWord from "./logo/BibleAppLogo.png"
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import HamburgerIcon from "./Icons/hamburgericon.png"
import { motion } from "framer-motion";

function Nav(props: any) {
    const [hamburgerView, setHamburgerView] = useState(false)
    const { height, width } = useWindowDimensions();
    const location = useLocation();

    useEffect(() => {
        props.hamburgerMenu(hamburgerView)
    }, [hamburgerView])

    useEffect(() => {
        setHamburgerView(false)
    }, [location.pathname])

    const LinksView = () => {
    
        if (width > 1500) {
            return(
                <div className= {styles['links-container']}> 
                    <Link to="/" className={styles['link']}>
                        <div className={styles['link-text']}>Home</div>
                    </Link>
                    <Link to="/about" className={styles['link']}>
                        <div className={styles['link-text']}>About</div>
                    </Link>
                    <LoginStatus />
                </div>
            ) 
        }
        else if (width > 1150) {
            return(
                <div className= {styles['links-container']}> 
                    <Link to="/" className={styles['link']}>
                        <div className={styles['link-text']}>Home</div>
                    </Link>
                    <Link to="/about" className={styles['link']}>
                        <div className={styles['link-text']}>About</div>
                    </Link>
                    <LoginStatus />
                </div>
            )
        } else {
            return(
                <div className= {styles['links-container']}> 
                    <button className = {styles['hamburger-container']} onClick={() => {setHamburgerView(!hamburgerView)}}>
                        <img className={styles['hamburger-menu-icon']} src={HamburgerIcon} alt="Hamburger Menu Icon" />
                    </button>
                </div>
            )
        }
    }

    const HamburgerMenu = () => {
        if (hamburgerView === true && location.pathname !== "/" && width < 1150) {
            return(
                <motion.div className = {styles['Hamburger']}> 
                    <div className= {styles['hamburger-links-container']}> 
                        <Link to="/" className={styles['link']}>
                            <div className={styles['link-text']}>Home</div>
                        </Link>
                        <Link to="/challenges" className={styles['link']}>
                            <div className={styles['link-text']}>Suggested Stories</div>
                        </Link>
                        <Link to="/about" className={styles['link']}>
                            <div className={styles['link-text']}>About</div>
                        </Link>
                        <LoginStatus />
                    </div>
                </motion.div>
            )
        }
    }

    return ( 
    <>
        <nav className = {styles.Navbar}> 
            <div className= {styles['logo-container']}>
                <Link to="/">
                    <img className={styles['logo']} src={OneMoreWord} alt="One More Word Logo"/>
                </Link>
            </div>
                {LinksView()} 
        </nav>
        {HamburgerMenu()}
    </>
    )
}
export default Nav