import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../API/FirebaseConfig";
import { query, collection, getDocs, where } from "firebase/firestore";
import styles from './Nav.module.css';

function LoginStatus() {
    const [loggedIn, setLoggedIn] = useState<boolean>()
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const fetchUserName = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setName(data.name);
            if (user != null) {localStorage.setItem('user', user.uid); setLoggedIn(true)}
        } catch (err) {
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };
    
    useEffect(() => {
        if (loading) return;
        if (!user) return setLoggedIn(false)
        fetchUserName();
    }, [user, loading])

    function LoggedIn() {
        if(loggedIn) {
            return(
                <>
                    <Link to="/dashboard" className={styles['link']}>
                        <div className={styles['link-user-text']}>{name}</div>
                    </Link>
                    <button className={styles['link-button']} onClick={() => {logout(); setLoggedIn(false)}}>
                        <div className={styles['link-button-text']}> Logout </div>
                    </button>
                </>
            )
        } else {
            return(
            <Link to={"/login"} className={styles['link']}>
                <div className={styles['link-text']}> Login </div>
            </Link>
            )
        }
    }

    useEffect(() => {
        LoggedIn()
        if (!loggedIn) {localStorage.removeItem('user')}
    }, [loggedIn])

    return(
        <>
            <LoggedIn />
        </>
    )
}
export default LoginStatus