import { initializeApp } from "firebase/app";
import { 
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  Unsubscribe
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  onSnapshot
} from "firebase/firestore"
import { Bookmark } from "../models/bookmark.interface";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCHfKzv_0u1K38hmnbrGjFbGOU5w32EGlo",
  authDomain: "biblemem-b8258.firebaseapp.com",
  projectId: "biblemem-b8258",
  storageBucket: "biblemem-b8258.appspot.com",
  messagingSenderId: "446960750543",
  appId: "1:446960750543:web:0412e10a69197516624cba"
};

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name: any, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
    await addDoc(collection(db, "bookmarks"), {
      reference: "null",
      book: "null",
      chapter: "null",
      verse: "null",
      translation: "null", 
      text: "null",
      uid: user.uid,
      id: "null", 
      study: "null"
    })
    await addDoc(collection(db, "studies"), {
      id: "null",
      name: "null",
      uid: user.uid
    })
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const subscribeToBookmarkList = async (
  onChange: (items: Bookmark[]) => void,
  userId: string,
  study: string,
): Promise<Unsubscribe> => {
  const q = query(collection(db, "bookmarks"), where("uid", "==", userId), where("study", "==", study))
    return onSnapshot(q, (col) => {
      onChange(
          col.docs.map((doc) => {
              const data = doc.data();
              const id = doc.id;
              return { id, ...data };
          }) as Bookmark[]
      );
      if(col.docs.length === 0) {
        console.log("No bookmarks")
      }
  });
};

const logout = () => {
  signOut(auth);
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  subscribeToBookmarkList,
};